<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer" clipped>
      <v-list nav>
        <v-sheet
          v-for="item in drawerItems"
          :key="item.title"
          color="transparent"
        >
          <v-list-group
            v-if="item.items && item.canAccess"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              link
              v-for="child in item.items"
              :key="child.title"
              :to="{ name: child.pathName }"
              :inactive="child.inactive"
            >
              <v-list-item-content>
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            link
            v-else-if="item.canAccess"
            color="primary"
            :to="{ name: item.pathName }"
            :inactive="item.inactive"
          >
            <v-list-item-icon>
              <v-icon>{{ item.action }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-sheet>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app outlined flat clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-sheet class="d-flex align-center px-5 py-3" color="transparent">
        <v-sheet class="text-h6 primary--text ms-1" color="transparent"
          >dealpartner.lk</v-sheet
        >
      </v-sheet>
      <v-spacer></v-spacer>
      <!-- <v-btn icon>
        <v-icon>mdi-bell-outline</v-icon>
      </v-btn> -->

      <v-menu :close-on-content-click="false" :nudge-width="200" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-avatar color="primary" size="36">
              <span class="white--text text-h5">{{
                auth.display_name.charAt(0)
              }}</span>
            </v-avatar>
          </v-btn>
        </template>

        <v-card outlined>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  {{ auth.display_name }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ auth.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list class="pb-0">
            <v-list-item>
              <v-list-item-title>Enable dark theme</v-list-item-title>
              <v-list-item-action>
                <v-switch v-model="isDark" dense></v-switch>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title>Update app</v-list-item-title>
              <v-list-item-action>
                <v-btn icon @click="$router.go()">
                  <v-icon>mdi-update</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title>Logout</v-list-item-title>
              <v-list-item-action>
                <v-btn icon color="error" @click="logout()">
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-banner single-line v-if="!isOnline">
        <div
          class="d-flex justify-center align-center secondary--text text-caption"
        >
          <v-icon slot="icon" color="secondary" class="me-2" small>
            mdi-cloud-off-outline
          </v-icon>
          You are offline.
        </div>
      </v-banner>
      <v-breadcrumbs :items="breadcrumbsItems" divider=".">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.to" :disabled="item.disabled" exact>
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <v-container fluid>
        <router-view :key="$route.fullPath"></router-view>
      </v-container>

      <v-snackbar
        bottom
        left
        v-model="isMessage"
        timeout="5000"
        :vertical="error.isError"
      >
        <v-icon class="me-2" color="error" v-if="error.isError">
          mdi-alert-circle-outline
        </v-icon>
        <v-icon class="me-2" color="primary" v-else>
          mdi-check-circle-outline
        </v-icon>
        {{ error.error }}

        <template v-slot:action="{ attrs }">
          <v-btn
            :color="error.isError ? 'error' : 'primary'"
            text
            v-bind="attrs"
            @click="isMessage = false"
          >
            {{ error.isError ? "Close" : "OK" }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>

    <v-footer class="d-flex justify-end" app>
      <v-sheet
        class="font-weight-light text-caption grey--text"
        color="transparent"
      >
        © 2024, dealpartner.lk. All rights reserved. dealpartner.lk version Beta
        {{ $version }}
      </v-sheet>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: mapState({
    auth: (state) => state.auth.data,
    error: (state) => state.error.data,
    settings: (state) => state.settings.data,
  }),
  components: {
    //
  },
  data: () => ({
    isOnline: navigator.onLine,
    isMessage: false,
    isDark: false,
    breadcrumbsItems: [],
    drawer: window.innerWidth > 1264,
    drawerItems: [],
  }),
  watch: {
    isDark(val) {
      this.$vuetify.theme.dark = val;
      this.$store.commit("updateSettings", {
        isDark: val,
      });
    },
    error(newError) {
      if (newError) {
        this.isMessage = true;
      }
    },
    isMessage(newValue) {
      if (newValue === false) {
        this.$store.dispatch("resetError");
      }
    },
  },
  async created() {
    if (this.settings) {
      this.isDark = this.settings.isDark;
      this.$vuetify.theme.dark = this.settings.isDark;
    } else {
      this.isDark = this.$vuetify.theme.dark;
      this.$store.commit("updateSettings", {
        isDark: this.isDark,
      });
    }
    this.breadcrumbsItems = this.$router.currentRoute.meta.breadcrumbsItems;

    this.drawerItems = [
      {
        action: "mdi-view-dashboard-outline",
        title: "Dashboard",
        pathName: "PageDashboard",
        canAccess: true,
      },
      {
        action: "mdi-shape-outline",
        title: "Main Categories",
        pathName: "PageCategoryList",
        canAccess: true,
      },
      {
        action: "mdi-shape-outline",
        title: "Sub Categories",
        pathName: "PageSubCategoryList",
        canAccess: true,
      },
      {
        action: "mdi-shape-outline",
        title: "Sub Sub Categories",
        pathName: "PageSubSubCategoryList",
        canAccess: true,
      },
      {
        action: "mdi-shape-outline",
        title: "Items ",
        pathName: "PageItemList",
        canAccess: true,
      },
      {
        action: "mdi-comment-question-outline",
        title: "FAQs ",
        pathName: "PageFAQList",
        canAccess: true,
      },
      {
        action: "mdi-panorama-variant-outline",
        title: "Heros ",
        pathName: "PageHeroList",
        canAccess: true,
      },
      {
        action: "mdi-image-auto-adjust",
        title: "Adds ",
        pathName: "PageAddList",
        canAccess: true,
      },
      {
        action: "mdi-message-text-outline",
        title: "Reviews ",
        pathName: "PageReviews",
        canAccess: true,
        inactive: true,
      },
      {
        action: "mdi-help-circle-outline",
        title: "Why ",
        pathName: "PageWhyList",
        canAccess: true,
      },
      {
        action: "mdi-account-question-outline",
        title: "Who ",
        pathName: "PageWhoList",
        canAccess: true,
      },
      {
        action: "mdi-account-multiple-outline",
        title: "Users ",
        pathName: "PageUserList",
        canAccess: true,
      },
      // {
      //   action: "mdi-car-multiple",
      //   items: [{ title: "List Item" }],
      //   title: "Vehicles ",
      //   canAccess: true
      // },
    ];
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
  updated() {
    this.breadcrumbsItems = this.$router.currentRoute.meta.breadcrumbsItems;
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "PageAuthSignin" });
    },
    async updateOnlineStatus() {
      this.isOnline = navigator.onLine;
      if (this.isOnline) {
        this.$router.go();
      }
    },
  },
};
</script>